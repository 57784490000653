/* Cubic Bezier Transition */
/*********** 
    Page Header
    ***********/
/* Header search bar, toggler button & top menu */
.page-header.navbar {
  background-color: #4f5a67;
  /* Top notification menu/bar */
  /* Header seaech box */
  /* Toggler button for sidebar expand/collapse and responsive sidebar menu */ }
  .page-header.navbar .top-menu .navbar-nav {
    /* Extended Dropdowns */
    /* Notification */
    /* Inbox */
    /* Tasks */
    /* User */
    /* Language */
    /* Dark version */ }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle > i {
      color: #9fa7b1; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle .badge.badge-default {
      background-color: #31c7b2;
      color: #ffffff; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover {
      background-color: #657384; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover > i {
        color: #c9ced3; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle {
      background-color: #657384; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle > i {
        color: #c9ced3; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
      border-color: #e7eaf0; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:after {
        border-bottom-color: #eaedf2; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external {
        background: #eaedf2; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > h3 {
          color: #62878f; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a {
          color: #337ab7; }
          .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a:hover {
            color: #23527c;
            text-decoration: none; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a {
        border-bottom: 1px solid #EFF2F6 !important;
        color: #888888; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a:hover {
          background: #f8f9fa; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a .time {
      background: #f1f1f1; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a:hover .time {
      background: #e4e4e4; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .circle {
      background-color: #31c7b2;
      color: #ffffff; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .corner {
      border-color: transparent transparent transparent #31c7b2; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu .dropdown-menu-list .subject .from {
      color: #5b9bd1; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list .progress {
      background-color: #dfe2e9; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > .username {
      color: #d6dae0; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > i {
      color: #d6dae0; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-menu {
      width: 195px; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > .langname {
      color: #d6dae0; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu {
      background: #657384;
      border: 0; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu:after {
        border-bottom-color: #657384; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external {
        background: #535f6d; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > h3 {
          color: #dcdfe4; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > a:hover {
          color: #5496cf; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a,
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a {
        color: #eaecef;
        border-bottom: 1px solid #748294 !important; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a > i,
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a > i {
          color: #cdd2d9; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a:hover,
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a:hover {
          background: #6e7d90; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a {
        border-bottom: 0 !important; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li.divider {
        background: #748294; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a .time {
      background: #5a6676; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a:hover .time {
      background: #4f5a67; }
  .page-header.navbar .search-form {
    background: #46505c; }
    .page-header.navbar .search-form:hover {
      background: #657384; }
    .page-header.navbar .search-form .input-group .form-control {
      color: #c4c9d0; }
      .page-header.navbar .search-form .input-group .form-control::-moz-placeholder {
        color: #c1c6ce;
        opacity: 1; }
      .page-header.navbar .search-form .input-group .form-control:-ms-input-placeholder {
        color: #c1c6ce; }
      .page-header.navbar .search-form .input-group .form-control::-webkit-input-placeholder {
        color: #c1c6ce; }
    .page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
      color: #c4c9d0; }
    .page-header.navbar .search-form.open {
      background: #657384; }
  .page-header.navbar .menu-toggler > span,
  .page-header.navbar .menu-toggler > span:before,
  .page-header.navbar .menu-toggler > span:after {
    background: #d6dae0; }
  .page-header.navbar .menu-toggler > span:hover {
    background: #d6dae0; }
    .page-header.navbar .menu-toggler > span:hover:before, .page-header.navbar .menu-toggler > span:hover:after {
      background: #d6dae0; }
  .page-header.navbar .menu-toggler.th-toggle-exit > span {
    background-color: transparent !important; }

/* Default Horizontal Menu */
.page-header.navbar {
  /* Default Mega Menu */
  /* Light Mega Menu */ }
  .page-header.navbar .hor-menu .navbar-nav {
    /* Mega menu content */
    /* Classic menu */ }
    .page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu {
      box-shadow: 5px 5px rgba(101, 115, 132, 0.2); }
      .page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > h3 {
        color: #f3f4f6; }
    .page-header.navbar .hor-menu .navbar-nav > li > a {
      color: #d6dae0; }
      .page-header.navbar .hor-menu .navbar-nav > li > a > i {
        color: #abb3be; }
    .page-header.navbar .hor-menu .navbar-nav > li:hover > a,
    .page-header.navbar .hor-menu .navbar-nav > li.open > a,
    .page-header.navbar .hor-menu .navbar-nav > li > a:hover {
      color: #e4e7eb;
      background: #657384 !important; }
      .page-header.navbar .hor-menu .navbar-nav > li:hover > a > i,
      .page-header.navbar .hor-menu .navbar-nav > li.open > a > i,
      .page-header.navbar .hor-menu .navbar-nav > li > a:hover > i {
        color: #b9c0ca; }
    .page-header.navbar .hor-menu .navbar-nav > li.active > a,
    .page-header.navbar .hor-menu .navbar-nav > li.active > a, .page-header.navbar .hor-menu .navbar-nav > li.current > a,
    .page-header.navbar .hor-menu .navbar-nav > li.current > a {
      color: white;
      background: #31c7b2 !important; }
      .page-header.navbar .hor-menu .navbar-nav > li.active > a > i,
      .page-header.navbar .hor-menu .navbar-nav > li.active > a > i, .page-header.navbar .hor-menu .navbar-nav > li.current > a > i,
      .page-header.navbar .hor-menu .navbar-nav > li.current > a > i {
        color: #abb3be; }
    .page-header.navbar .hor-menu .navbar-nav > li.active .selected, .page-header.navbar .hor-menu .navbar-nav > li.current .selected {
      border-top: 6px solid #31c7b2; }
    .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu {
      box-shadow: 5px 5px rgba(101, 115, 132, 0.2);
      background: #657384; }
      .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a {
        color: #d6dae0; }
        .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li > a > i {
          color: #d6dae0; }
      .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li:hover > a {
        color: #fefeff;
        background: #6e7d90; }
        .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li:hover > a > i {
          color: #fefeff; }
      .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a,
      .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a:hover, .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a,
      .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a:hover {
        color: #fefeff;
        background: #6e7d90; }
        .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a > i,
        .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.active > a:hover > i, .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a > i,
        .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.current > a:hover > i {
          color: #fefeff; }
      .page-header.navbar .hor-menu .navbar-nav > li .dropdown-menu li.divider {
        background-color: #717f92; }
    .page-header.navbar .hor-menu .navbar-nav > li .dropdown-submenu > a:after {
      color: #d6dae0; }
  .page-header.navbar .hor-menu.hor-menu-light .navbar-nav {
    /* Mega menu content */
    /* Classic menu */ }
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.mega-menu-dropdown > .dropdown-menu {
      box-shadow: 5px 5px rgba(102, 102, 102, 0.1); }
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.mega-menu-dropdown > .dropdown-menu .mega-menu-content .mega-menu-submenu li > h3 {
        color: #666; }
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a {
      color: #d6dae0; }
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a > i {
        color: #abb3be; }
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li:hover > a,
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a:hover {
      color: #e4e7eb;
      background: #657384; }
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li:hover > a > i,
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > a:hover > i {
        color: #b9c0ca; }
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.open > a {
      color: #333 !important;
      background: white !important; }
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.open > a > i {
        color: #333 !important; }
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a,
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a:hover, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a,
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a:hover {
      color: white;
      background: #31c7b2; }
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a > i,
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.active > a:hover > i, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a > i,
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li.current > a:hover > i {
        color: #abb3be; }
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu {
      box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
      background: white; }
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li > a {
        color: #000; }
        .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li > a > i {
          color: #888; }
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li:hover > a {
        color: #000;
        background: whitesmoke; }
        .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li:hover > a > i {
          color: #666; }
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a,
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a:hover, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a,
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a:hover {
        color: #000;
        background: whitesmoke; }
        .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a > i,
        .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.active > a:hover > i, .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a > i,
        .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.current > a:hover > i {
          color: #666; }
      .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu li.divider {
        background-color: whitesmoke; }
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li .dropdown-menu {
      border: 1px solid #f2f2f2; }
    .page-header.navbar .hor-menu.hor-menu-light .navbar-nav > li > .dropdown-menu {
      border-top: 0; }

/* Page sidebar */
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover,
.page-sidebar {
  background-color: #5e6672;
  /* Default sidebar */
  /* light sidebar */
  /* Sidebar search */ }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
  .page-sidebar .page-sidebar-menu {
    /* 1st level links */
    /* All links */ }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a,
    .page-sidebar .page-sidebar-menu > li > a {
      border-top: 1px solid #697380;
      color: #efefef; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i,
      .page-sidebar .page-sidebar-menu > li > a > i {
        color: #aab3bb; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class^="icon-"],
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class*="icon-"],
      .page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"],
      .page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"] {
        color: #b8c0c6; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li > a > .arrow.open:before {
        color: #aab3bb; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading > h3,
    .page-sidebar .page-sidebar-menu > li.heading > h3 {
      color: #b4b4b4; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a,
    .page-sidebar .page-sidebar-menu > li:hover > a,
    .page-sidebar .page-sidebar-menu > li.open > a {
      background: #535f6d;
      color: #efefef; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > i,
      .page-sidebar .page-sidebar-menu > li:hover > a > i,
      .page-sidebar .page-sidebar-menu > li.open > a > i {
        color: #aab3bb; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li:hover > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li:hover > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li.open > a > .arrow.open:before {
        color: #aab3bb; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a,
    .page-sidebar .page-sidebar-menu > li.active > a,
    .page-sidebar .page-sidebar-menu > li.active.open > a {
      background: #31c7b2;
      border-top-color: transparent;
      color: #ffffff; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a:hover, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a:hover,
      .page-sidebar .page-sidebar-menu > li.active > a:hover,
      .page-sidebar .page-sidebar-menu > li.active.open > a:hover {
        background: #31c7b2; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > i,
      .page-sidebar .page-sidebar-menu > li.active > a > i,
      .page-sidebar .page-sidebar-menu > li.active.open > a > i {
        color: #ffffff; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li.active > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li.active > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li.active.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li.active.open > a > .arrow.open:before {
        color: #ffffff; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active + li > a,
    .page-sidebar .page-sidebar-menu > li.active + li > a {
      border-top-color: transparent; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open + li > a,
    .page-sidebar .page-sidebar-menu > li.active.open + li > a {
      border-top-color: #697380; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:last-child > a,
    .page-sidebar .page-sidebar-menu > li:last-child > a {
      border-bottom: 1px solid transparent !important; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before,
    .page-sidebar .page-sidebar-menu li > a > .arrow:before,
    .page-sidebar .page-sidebar-menu li > a > .arrow.open:before {
      color: #aab3bb; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow.open:before,
    .page-sidebar .page-sidebar-menu li:hover > a > .arrow:before,
    .page-sidebar .page-sidebar-menu li:hover > a > .arrow.open:before {
      color: #aab3bb; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow.open:before,
    .page-sidebar .page-sidebar-menu li.active > a > .arrow:before,
    .page-sidebar .page-sidebar-menu li.active > a > .arrow.open:before {
      color: #ffffff; }
    .page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:hover .sub-menu, .page-sidebar-closed
    .page-sidebar .page-sidebar-menu:hover .sub-menu {
      background-color: #5e6672; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li > a {
      color: #eeeeee; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > i {
        color: #aab3bb; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"],
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"] {
        color: #b8c0c6; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow.open:before {
        color: #aab3bb; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li.open > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li.active > a {
      background: #566170 !important; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li.open > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li.active > a > i {
        color: #aab3bb;
        color: #e3e6e8; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before {
        color: #aab3bb; }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light,
  .page-sidebar .page-sidebar-menu.page-sidebar-menu-light {
    /* 1st level links */ }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.open > a,
    .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li:hover > a,
    .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.open > a {
      background: #545c67; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a,
    .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a,
    .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
      background: #4d555e;
      border-left: 4px solid #31c7b2;
      color: #f1f1f1; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover {
        border-left: 4px solid #31c7b2;
        background: #545c67; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > i,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > i,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > i {
        color: #eeeeee; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow:before,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a > .arrow.open:before {
        color: #eeeeee; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu,
    .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
      background: #626b78; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.open > a,
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a {
        background: #59616c !important; }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler,
  .page-sidebar .sidebar-toggler {
    background: #525a64; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler > span,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler > span:before,
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler > span:after,
    .page-sidebar .sidebar-toggler > span,
    .page-sidebar .sidebar-toggler > span:before,
    .page-sidebar .sidebar-toggler > span:after {
      background: #d6dae0; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler > span:hover,
    .page-sidebar .sidebar-toggler > span:hover {
      background: #d6dae0; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler > span:hover:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler > span:hover:after,
      .page-sidebar .sidebar-toggler > span:hover:before,
      .page-sidebar .sidebar-toggler > span:hover:after {
        background: #d6dae0; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler.th-toggle-exit > span,
    .page-sidebar .sidebar-toggler.th-toggle-exit > span {
      background-color: transparent !important; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-toggler:hover,
    .page-sidebar .sidebar-toggler:hover {
      background: #474d56; }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group,
  .page-sidebar .sidebar-search .input-group {
    border-bottom: 1px solid #707b88; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control,
    .page-sidebar .sidebar-search .input-group .form-control {
      background-color: #5e6672;
      color: #7d8795; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control::-moz-placeholder,
      .page-sidebar .sidebar-search .input-group .form-control::-moz-placeholder {
        color: #7d8795;
        opacity: 1; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control:-ms-input-placeholder,
      .page-sidebar .sidebar-search .input-group .form-control:-ms-input-placeholder {
        color: #7d8795; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .form-control::-webkit-input-placeholder,
      .page-sidebar .sidebar-search .input-group .form-control::-webkit-input-placeholder {
        color: #7d8795; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search .input-group .input-group-btn .btn > i,
    .page-sidebar .sidebar-search .input-group .input-group-btn .btn > i {
      color: #7d8795; }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-bordered .input-group,
  .page-sidebar .sidebar-search.sidebar-search-bordered .input-group {
    border: 1px solid #707b88; }
  .page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.open .input-group, .page-sidebar-closed
  .page-sidebar .sidebar-search.open .input-group {
    background-color: #5e6672; }
  .page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.open .remove > i, .page-sidebar-closed
  .page-sidebar .sidebar-search.open .remove > i {
    color: #7d8795; }
  .page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid .input-group, .page-sidebar-closed
  .page-sidebar .sidebar-search.sidebar-search-solid .input-group {
    background: none; }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid .input-group,
  .page-sidebar .sidebar-search.sidebar-search-solid .input-group {
    border: 1px solid #4b525c;
    background: #4b525c; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid .input-group .form-control,
    .page-sidebar .sidebar-search.sidebar-search-solid .input-group .form-control {
      background: #4b525c; }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid.open .input-group,
  .page-sidebar .sidebar-search.sidebar-search-solid.open .input-group {
    border: 1px solid #5e6672;
    background: #5e6672; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .sidebar-search.sidebar-search-solid.open .input-group .form-control,
    .page-sidebar .sidebar-search.sidebar-search-solid.open .input-group .form-control {
      background: #5e6672; }

.page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light {
  /* 1st level links */ }
  .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
    border-left: 0;
    border-right: 4px solid #31c7b2; }
    .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar-reversed .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover {
      border-left: 0;
      border-right: 4px solid #31c7b2; }

/******
    Page Footer 
    ******/
.page-footer .page-footer-inner {
  color: #c9cdd3; }

.page-footer-fixed .page-footer {
  background-color: #4d555e; }

@media (min-width: 992px) {
  /* 992px */
  /* Sidebar menu closed */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(83, 95, 109, 0.2); }
    .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu.sidebar-toggler-wrapper, .page-sidebar-menu.page-sidebar-menu-hover-submenu > li:hover > .sub-menu.sidebar-search-wrapper {
      box-shadow: none; }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    box-shadow: 5px 5px rgba(83, 95, 109, 0.2); }
    .page-sidebar-menu.page-sidebar-menu-closed > li:hover.sidebar-toggler-wrapper, .page-sidebar-menu.page-sidebar-menu-closed > li:hover.sidebar-search-wrapper {
      box-shadow: none; }
    .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
      box-shadow: 5px 5px rgba(83, 95, 109, 0.2); }
      .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu.sidebar-toggler-wrapper, .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu.sidebar-search-wrapper {
        box-shadow: none; }
  /* Light sidebar menu */
  .page-sidebar-menu.page-sidebar-menu-light.page-sidebar-menu-closed > li.heading {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid #697380 !important; }
  /* Fixed Sidebar */
  .page-sidebar-fixed:not(.page-footer-fixed) .page-content {
    border-bottom: 0; }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer {
    background-color: #fff; }
    .page-sidebar-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner {
      color: #333; }
  /* Boxed Layout */
  .page-boxed {
    background-color: #575f6a !important;
    /* Page container */
    /* Page sidebar */
    /* Page footer */ }
    .page-boxed .page-container {
      background-color: #5e6672;
      border-left: 1px solid #697380;
      border-bottom: 1px solid #697380; }
    .page-boxed.page-sidebar-reversed .page-container {
      border-left: 0;
      border-right: 1px solid #697380; }
    .page-boxed.page-sidebar-fixed .page-container {
      border-left: 0;
      border-bottom: 0; }
    .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-container {
      border-left: 0;
      border-right: 0;
      border-bottom: 0; }
    .page-boxed.page-sidebar-fixed .page-sidebar {
      border-left: 1px solid #697380; }
    .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-sidebar {
      border-right: 1px solid #697380;
      border-left: 0; }
    .page-boxed.page-sidebar-fixed.page-footer-fixed .page-footer {
      background-color: #575f6a !important; }
      .page-boxed.page-sidebar-fixed.page-footer-fixed .page-footer .page-footer-inner {
        color: #c9cdd3; }
  /* Sidebar Menu Wirh Hoverable Submenu */
  .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-right: 8px solid #5c6979; }
    .page-sidebar-reversed .page-sidebar-menu-hover-submenu li:hover a > .arrow {
      border-left: 8px solid #5c6979; }
  .page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    background: #5c6979 !important; } }

@media (max-width: 991px) {
  /* 991px */
  /* Page sidebar */
  .page-sidebar {
    background-color: #474d56;
    /* light sidebar */ }
    .page-sidebar .page-sidebar-menu > li > a {
      border-top: 1px solid #5e6672; }
    .page-sidebar .page-sidebar-menu > li:hover > a, .page-sidebar .page-sidebar-menu > li.open > a {
      background: #4d555e; }
    .page-sidebar .page-sidebar-menu > li:last-child > a {
      border-bottom: 0 !important; }
    .page-sidebar .page-sidebar-menu > li .sub-menu {
      background-color: #474d56 !important; }
    .page-sidebar .page-sidebar-menu .sidebar-search input {
      background-color: #474d56 !important; }
    .page-sidebar .page-sidebar-menu.page-sidebar-menu-light {
      /* 1st level links */ }
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li:hover > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.open > a {
        background: #4d555e; }
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a {
        background: #4d555e; }
        .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active > a:hover, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li.active.open > a:hover {
          background: #4d555e; }
      .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu {
        background: #474d56 !important; }
        .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li:hover > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.open > a, .page-sidebar .page-sidebar-menu.page-sidebar-menu-light > li .sub-menu > li.active > a {
          background: #4d555e !important; } }

@media (max-width: 480px) {
  /* 480px */
  .page-header.navbar {
    /* Top menu */ }
    .page-header.navbar .top-menu {
      background-color: #5e6672; }
      .page-header-fixed-mobile .page-header.navbar .top-menu {
        background-color: #4f5a67; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
        background-color: #677587; }
        .page-header-fixed-mobile .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle {
          background: none; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-user .dropdown-toggle:hover {
          background-color: #657384; } }

/****
    Boby
    ****/
body {
  background-color: #5e6672; }

/****
 CSS3 Spinner Bar
****/
.page-spinner-bar > div,
.block-spinner-bar > div {
  background: #41d0bc; }

/* Page sidebar */
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
.page-sidebar .page-sidebar-menu {
  /* Change sidebar link font weight */ }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a,
  .page-sidebar .page-sidebar-menu li > a {
    font-weight: 300; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > i,
    .page-sidebar .page-sidebar-menu li > a > i {
      font-weight: 300; }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li .sub-menu li > a,
  .page-sidebar .page-sidebar-menu li .sub-menu li > a {
    font-weight: 300; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li .sub-menu li > a > i,
    .page-sidebar .page-sidebar-menu li .sub-menu li > a > i {
      font-weight: 300; }
